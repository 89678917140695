<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
      <a-form layout="inline" :form="queryForm">
        <a-row :gutter="16">
          <a-col :md="6" :sm="24">
            <a-form-item label="订单号">
              <a-input v-model="queryParam.orderNo" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="下单人手机号">
              <a-input v-model="queryParam.mobile" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="是否需提货">
              <a-select v-model="queryParam.loadGoods" placeholder="请选择" default-value="0">
                <a-select-option value="-1">全部</a-select-option>
                <a-select-option value="1">需要</a-select-option>
                <a-select-option value="0">不需要</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="企业客户">
<!--              <a-input v-model="queryParam.paymentCustomerCode" style="width: 100%" placeholder="客户识别码" />-->
              <a-select v-model="queryParam.paymentCustomerCode" placeholder="请选择" >
                <a-select-option v-for="item in qyCustomers" :key="item.code">{{ item.nickname }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :md="6" :sm="24">
            <a-form-item label="下单时间从：">
              <a-date-picker v-model="queryParam.orderDateStart" style="width: 100%" placeholder="请输入日期"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="到" class="dislodge-script">
              <a-date-picker v-model="queryParam.orderDateEnd" style="width: 100%" placeholder="请输入日期"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="提货状态">
              <a-select  placeholder="请选择" v-model="queryParam.loadStatus" default-value="">
<!--              <a-select  placeholder="请选择"  @change="changeLoadStatus" v-decorator="['queryParam.loadStatus']">-->
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">待调度</a-select-option>
                <a-select-option value="1">提货中</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="!advanced && 4 || 24" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary"  @click="openDispatch()" v-action:but_less_load_dispatch>分配提货司机</a-button>&nbsp;&nbsp;
      <a-button type="primary"  @click="updateOrderWarehouse()" v-action:but_less_load_updatewarehouse>分配到库地址</a-button>&nbsp;&nbsp;
      <a-button type="primary"  @click="exportOrders()" >下载订单详情</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{ x: 'max-content' , y: scrollHeight}"
    >
      <!--      <span slot="serial" slot-scope="text, record, index">-->
      <!--        {{ index + 1 }}-->
      <!--      </span>-->

      <span slot="action" slot-scope="text, record" >
          <template>
            <a @click="orderInfo(record)" v-action:but_less_load_orderinfo>详情 &ensp;|&ensp;</a> <!--如果需要权限 v-action:roleBD -->
             <a @click="gotoWarehouse(record)" v-action:but_less_load_inwarehouse>入库管理</a>
          </template>
        </span>

    </s-table>

    <a-modal
      key="dispatch"
      title="调度"
      :width="500"
      :visible="visible_add"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="dispatchHandleCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{height:'auto'}"
    >
      <div>
        <a-form @submit="handleSubmit" :form="form">
          <a-form-item
            label="选择"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-select
              placeholder="选择司机"
              @change="handleChange2"
              v-decorator="[ 'driverId']"
            >
              <a-select-option v-for="(item, index) in driverList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>

          </a-form-item>

          <a-form-item
            label="司机姓名"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-input
              v-decorator="[
            'name',
            {rules: [{ required: true, message: '请输入名称' }]}
            ]"
            :readonly="!newDriver"
              name="name"/>
          </a-form-item>

          <a-form-item
            label="司机电话"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-input
              v-decorator="[
            'mobile',
            {rules: [{ required: true, message: '请输入名称' }]}
          ]"
              :readonly="!newDriver"
              name="mobile"/>
          </a-form-item>

          <a-form-item
            label="预约时间"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-date-picker
              style="width:100%"
              :show-time="{ format: 'HH' }"
              format="YYYY-MM-DD HH:00:00"
              v-decorator="[
                'planStartDate',
                {rules: [{ required: true, message: '请输入名称' }]}
              ]"
              name="planStartDate"/>
          </a-form-item>

          <a-form-item
            label="仓库名称"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-select
              placeholder="请选择仓库"
              @change="handleChange3"
              v-decorator="[ 'warehouseId',
               {rules: [{ required: true, message: '请选择仓库' }]}
              ]"
            >
              <a-select-option v-for="(item, index) in warehouseList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            label="选择车辆"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-select
              placeholder="请选择"
              @change="handleChange4"
              v-decorator="[ 'vehicleId',
               {rules: [{ required: false, message: '请选择车辆' }]}
              ]"
            >
              <a-select-option v-for="(item, index) in vehicleList" :key="index" :value="item.id">
                {{ item.vno }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            label="车牌号"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-input
              v-decorator="[
            'vno',
            {rules: [{ required: false, message: '车牌号' }]}
          ]"
              :readonly="!newVehicle"
              name="vno"/>
          </a-form-item>

          <a-form-item
            :wrapperCol="{ span: 24 }"
            class="form-submit"
          >
            <a-button style="margin-right: 8px" @click="dispatchHandleCancel">取消</a-button>
            <a-button htmlType="submit" type="primary">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>



    <a-modal
      key="updateLoadWarehouse"
      title="更改提货到库地址"
      :width="500"
      :visible="visible_add2"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="dispatchHandleCancel2"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{height:'auto'}"
    >
      <div>
        <a-form @submit="handleSubmit2" :form="form2">
          <a-form-item
            label="选择仓库"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }">
            <a-select
              placeholder="请选择"
              @change="handleChange3"
              v-decorator="[ 'warehouseId',
                {rules: [{ required: true, message: '请选择仓库' }]}
              ]"
            >
              <a-select-option v-for="(item, index) in warehouseList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            label="仓库名称"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入名称' }] }]" name="name"    :readonly="warehousereRead"/>
          </a-form-item>

          <a-form-item
            label="仓库地址"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-cascader
              :options="addressoptions"
              placeholder="请选择"
              v-decorator="['addressxz', { rules: [{ required: true, message: '请选择省市区' }] }]"
              :readonly="warehousereRead"
            />
          </a-form-item>

          <a-form-item
            label="仓库地址"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >

            <a-textarea
              v-decorator="['address', { rules: [{ required: true, message: '请输入地址' }] }]"
              name="address"
              :readonly="warehousereRead"
            ></a-textarea>
          </a-form-item>

          <a-form-item
            label="营业时间"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="['businessHours', { rules: [{ required: true, message: '请输入营业时间' }] }]"
              placeholder="8:00-9:00"
              name="businessHours"
              :readonly="warehousereRead"
            />
          </a-form-item>

          <a-form-item
            label="联系人"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="['contacts', { rules: [{ required: true, message: '请输入联系人' }] }]"
              name="contacts"
              :readonly="warehousereRead"
            />
          </a-form-item>

          <a-form-item
            label="联系方式"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="['contactTel', { rules: [{ required: true,pattern: /^1[34578]\d{9}$/, message: '请正确输入联系方式' }] }]"
              name="contactTel"
              :readonly="warehousereRead"
            />
          </a-form-item>

          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: right;margin:0"
          >
            <a-button style="margin-right: 8px" @click="dispatchHandleCancel2">取消</a-button>
            <a-button htmlType="submit" type="primary">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>


  </a-card>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import {
  findListDriver,
  orderFindPage,
  findListWarehouse,
  updateOrderWarehouse,
  loadDispacth,
  unLoadDispacth, findListCustomer, getGeoAddress, findListVehicle
} from '@/api/manage'
import Vue from "vue";
import {ACCESS_TOKEN} from "@/store/mutation-types";
import axios from "axios";

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      mdl: {},
      openKeys: ['1'],
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: {pageSizeOptions: ['50', '100','200','500']},
      treeData:[],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '订单号',
          width: '220px',
          key:'transactionNo',
          align: "center",
          dataIndex:'transactionNo',
          fixed: 'left',
        },
        {
          title: '下单时间',
          width: '220px',
          key:'orderDate',
          align: "center",
          dataIndex:'orderDate',
        },
        {
          title: '需要提货',
          width: '150px',
          key:'loadGoods',
          dataIndex:'loadGoods',
          align:"center",
          customRender: (text) => {
            let ret = ""
            if(text==1){
              ret = '需要提货'
            }else{
              ret = '自送到库'
            }
            return ret
          }
        },
        {
          title: '状态',
          width: '150px',
          key:'loadStatus',
          align: "center",
          dataIndex:'loadStatus',
          customRender: (text) => {
            let ret = ""
            if(text==0){
              ret = '待调度'
            }else if(text==1){
              ret = '提货中'
            }else{
              ret = '已入库'
            }
            return ret
          }
        },
        {
          title: '物品信息',
          width: '350px',
          key:'goodsInfo',
          align: "center",
          dataIndex:'goodsInfo'
        },
        {
          title: '温度',
          width: '150px',
          key:'temperature',
          align: "center",
          dataIndex:'temperature',
          customRender: (text) => {
            let ret = ""
            if(text==1){
              ret = '冷藏'
            }else if(text==2){
              ret = '冷冻'
            }else{
              ret = '常温'
            }
            return ret
          }
        },

        {
          title: '司机',
          width: '150px',
          key:'driver',
          align: "center",
          dataIndex:'driver'
        },
        {
          title: '到库库房',
          width: '150px',
          key:'warehouse',
          align: "center",
          dataIndex:'warehouse'
        },
        {
          title: '线路',
          width: '150px',
          key:'lineName',
          align: "center",
          dataIndex:'lineName'
        },
        {
          title: '运单号（提货）',
          width: '220px',
          key:'transportNo',
          align: "center",
          dataIndex:'transportNo'
        },
        {
          title: '取消状态',
          width: '150px',
          key: 'revokeType',
          align: 'center',
          dataIndex: 'revokeType',
          customRender: (text) => {
            let ret = ''
            if (text !=null && text>0) {
              ret = '已取消'
            } else {
              ret = '正常'
            }
            return ret
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        let param = {};
        param = Object.assign(parameter, this.queryParam)
        param.type=2
        param.loadStatusIn=[0,1]
        param.transportType=1
        if(this.queryParam.orderDateStart!=null){
          param.orderDateStart = this.queryParam.orderDateStart.format("YYYY-MM-DD")+" 00:00:00"
        }
        if(this.queryParam.orderDateEnd!=null){
          param.orderDateEnd = this.queryParam.orderDateEnd.format("YYYY-MM-DD")+" 23:59:59"
        }
        param.paymentCustomerCode = this.queryParam.paymentCustomerCode
        param.findPageOrderBys = "load_status asc"

        let qloadStatus = this.$route.params.loadStatus
        console.info("----------页面默认传参"+qloadStatus);
        if(param.loadStatus==null&&qloadStatus!=null){
          param.loadStatus = qloadStatus
        }
        return orderFindPage(param)
          .then(res => {
            return res.result
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      confirmLoading:false,
      visible_add:false,
      visible_add2:false,
      form: this.$form.createForm(this),
      form2:this.$form.createForm(this),
      queryForm:this.$form.createForm(this),
      tabObj:{},//当前对象，编辑是用到
      addFlag: true,//true新增false编辑
      expandedKeys:[],//已绑定的keys
      submitKeys:[],//提交保存的key,包括半选中和选中
      driverList:[],
      driverInfoList:[],
      newDriver:true,//新司机，只有当选择手动录入司机是司机姓名电话可编辑
      newVehicle:false,//新车辆
      warehouseList:[],
      warehouseInfoList:[],
      vehicleList:[],
      scrollHeight:0,
      qyCustomers:[],
      addressoptions: [], //省市区选择数据
      warehousereRead:true
    }
  },
  created () {
    this.tableOption()
    this.scrollHeight = document.body.scrollHeight-455;
    findListCustomer({type:1})
      .then(res => {
        this.qyCustomers = []
        for(let i in res.result){
          this.qyCustomers.push(res.result[i])
        }

      }).catch((e)=>{
      this.$message.error("查询企业客户列表失败")
    })

  },

  activated() {
    let qloadStatus = this.$route.params.loadStatus
    console.info("默认传参：qloadStatus："+qloadStatus)
    if(qloadStatus!=null){

      this.queryParam.loadStatus = qloadStatus+""
    }else{
      this.queryParam = {}
    }
    this.$refs.table.refresh(true)
  },
  filters: {
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    updateStatus (record) {
      let targetstatus = 1;
      if(record.status==1){
        targetstatus = 0;
      }
      updateOrderWarehouse({id:record.id,status:targetstatus})
        .then(res => {
          //提交成功
          this.$message.success("更新成功")
          this.$refs.table.refresh()
        }).catch((e)=>{
        this.$message.error("更新失败")
      })
    },
    changeLoadStatus(e){
      console.info("--当前选中："+e)
      this.queryParam.loadStatus = e
      this.queryForm.setFieldsValue({
        "queryParam.loadStatus":e
      })

    },
    handleOk () {
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    openDispatch () {
      //判断是否包含不需调度的订单
      let that = this
      console.info("---"+this.selectedRows)
      if(this.selectedRows.length<=0){
        this.$message.error("请选择订单")
        return false;
      }
      for(let i in this.selectedRows){
        if(this.selectedRows[i].loadGoods!=1){
           this.$message.error("选择订单中包含自送到库的订单，不能调度")
            return false;
        }
      }
      for(let i in this.selectedRows){
        if(this.selectedRows[i].revokeType>0){
          this.$message.error("选择订单中包含已取消订单，不能调度")
          return false;
        }
      }

      this.visible_add = true
      this.tabObj = {}
      //初始化司机列表和仓库列表
      this.driverList=[]
      this.driverList.push({id:0,name:'手动录入'})
      findListDriver({status:1})
        .then(res => {
          for(let i in res.result){
            that.driverList.push({id:res.result[i].id,name:(res.result[i].name+res.result[i].mobile)})
          }
          that.driverInfoList = res.result
        })
      this.warehouseList =[]
      findListWarehouse({status:1})
        .then(res => {
          for(let i in res.result){
            that.warehouseList.push({id:res.result[i].id,name:(res.result[i].name)})
            that.warehouseInfoList.push(res.result[i])
          }
        })
      this.vehicleList =[]
      findListVehicle({status:1})
        .then(res => {
          that.vehicleList.push({id:0,vno:"手动录入"})
          for(let i in res.result){
            that.vehicleList.push({id:res.result[i].id,vno:(res.result[i].vno)})
          }
        })
    },
    unDispatch(){
      let that = this
      if(this.selectedRows.length<=0){
        this.$message.error("请选择订单")
        return false;
      }

      let param = {}
      param.type=1
      param.status=0
      param.orderIds=that.selectedRowKeys
      unLoadDispacth(param)
        .then(res => {
          console.info(res.code)
          if(res.code==0){
            that.$message.success("更新成功")
          }else{
            that.$message.error(res.message)
          }
        })
    },
    updateOrderWarehouse(){
      let that = this
      if(this.selectedRows.length<=0){
        this.$message.error("请选择订单")
        return false;
      }
      for(let i in this.selectedRows){
        if(this.selectedRows[i].loadGoods==1){
          this.$message.error("选择订单中包含需提货的订单，不能分配到库地址")
          return false;
        }
      }
      for(let i in this.selectedRows){
        if(this.selectedRows[i].revokeType>0){
          this.$message.error("选择订单中包含已取消订单，不能调度")
          return false;
        }
      }
      this.visible_add2 = true;
      this.tabObj = {}
      this.warehouseList =[]
      findListWarehouse({status:1})
        .then(res => {
          //增加请选择
          that.warehouseList.push({id:0,name:"手动录入"})
          for(let i in res.result){
            that.warehouseList.push({id:res.result[i].id,name:res.result[i].name})
            that.warehouseInfoList.push(res.result[i])
          }
        })
      getGeoAddress({ showDistrict: 1, showCity: 1 }).then((res) => {
        //提交成功
        that.addressoptions = res.result
      })

    },
    handleSubmit(e){
      e.preventDefault()
      let that = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          values.orderIds = that.selectedRowKeys
          values.type = 1
          values.driver = values.name
          values.driverTel = values.mobile
          values.planStartDate = values.planStartDate.format("YYYY-MM-DD HH:00:00");
          console.log('Received values of form: ', values)
          loadDispacth(values).then(res =>{
            if(res!=null){
              console.info(res.code)
              if(res.code==0){
                that.$message.success("更新成功")
                that.visible_add=false
                that.$refs.table.refresh()
              }else{
                that.$message.error(res.message)
              }
            }
          })
        }
      })
    },
    handleSubmit2(e){
      e.preventDefault()
      let that = this;
      this.form2.validateFields((err, values) => {
        if (!err) {
          values.orderIds = that.selectedRowKeys
          values.type = 1
          console.log('Received values of form: ', values)
          updateOrderWarehouse(values).then(res =>{
            if(res!=null){
              console.info(res.code)
              if(res.code==0){
                that.$message.success("更新成功")
                that.visible_add2=false
                that.$refs.table.refresh()
              }else{
                that.$message.error(res.message)
              }
            }
          })
        }
      })
    },
    iscandel(record){
      if(record.type==1){
        return false
      }else{
        return  true
      }
    },
    dispatchHandleCancel(){
      this.visible_add = false
    },
    dispatchHandleCancel2(){
      this.visible_add2 = false
    },
    handleChange2(value){
      if(value==0){
        this.newDriver = true
      }else{
        this.newDriver =false
        let selectedDriver = {}
        for(let i in this.driverInfoList){
          if(this.driverInfoList[i].id == value){
            selectedDriver = this.driverInfoList[i]
            break;
          }
        }

        this.form.setFieldsValue({
          name: selectedDriver.name,
          mobile:selectedDriver.mobile,
        })
      }
    },
    handleChange3(value){
      if(value==0){
        this.warehousereRead = false
        this.form2.setFieldsValue({
          name: "",
          addressxz: null,
          address: "",
          businessHours: "",
          contacts: "",
          contactTel: ""
        })
      }else {
        this.warehousereRead = true
        //插入数据
        let wimap = null
        for(var i in this.warehouseInfoList){
          if(this.warehouseInfoList[i].id==value){
            wimap = this.warehouseInfoList[i]
            break
          }
        }
        console.info(wimap)
        let addressxz = []
        if (wimap.administrativeCode != null) {
          let administrativeCodesp = wimap.administrativeCode.split(',')
          addressxz[0] = administrativeCodesp[0] - 0
          if (administrativeCodesp.length > 1) {
            addressxz[1] = administrativeCodesp[1] - 0
          }
          if (administrativeCodesp.length > 2) {
            addressxz[1] = administrativeCodesp[1] - 0
            addressxz[2] = administrativeCodesp[2] - 0
          }
        }
        this.form2.setFieldsValue({
          name: wimap.name,
          addressxz: addressxz,
          address: wimap.address,
          businessHours: wimap.businessHours,
          contacts: wimap.contacts,
          contactTel: wimap.contactTel
        })
      }
    },
    handleChange4(value){
      console.log(value)
      if(value==0){
        this.newVehicle = true;
        this.form.setFieldsValue({
          vno:""
        })
      }else {
        this.newVehicle = false;
        var vehinfo = null
        for(var i in this.vehicleList){
          if(this.vehicleList[i].id==value){
            vehinfo = this.vehicleList[i]
            break
          }
        }
        this.form.setFieldsValue({
          vno:vehinfo.vno
        })
      }

    },
    gotoWarehouse(record){
      if(record.loadStatus==0){
          this.$message.error("订单未调度，请先安排司机和仓库")
          return false
      }
      this.$router.push({name:'inStorage', query: {"orderId": record.id}})
    },
    orderInfo(record){
      //this.$router.push({path:'/orderServe/orderManagerDetail', query: {"orderSn": record.orderSn}})
      this.$router.push({ name: 'order_detail_dis', query: { 'orderSn': record.transactionNo,'fromroute':'LoadDispatch','orgOrderId':record.id}})
    },
    exportOrders(){
      let param = {}
      param = this.queryParam
      param.type = 2
      param.loadStatusIn=[0,1]
      param.transportType = 1
      param.warehouseId = this.thisWarehouse
      if(this.queryParam.orderDateStart!=null){
        param.orderDateStart = this.queryParam.orderDateStart.format("YYYY-MM-DD")+" 00:00:00"
      }
      if(this.queryParam.orderDateEnd!=null){
        param.orderDateEnd = this.queryParam.orderDateEnd.format("YYYY-MM-DD")+" 23:59:59"
      }
      param.paymentCustomerCode = this.queryParam.paymentCustomerCode
      let token = Vue.ls.get(ACCESS_TOKEN)
      axios({
        method: 'post',
        url: '/service_provider/order/exportLoadOrders',
        data: param,
        responseType: 'blob',
        headers: {
          token: token,
          'Content-Type': 'application/json;charset=UTF-8',
        },
      }).then((res) => {
        const link = document.createElement('a')
        let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)

        // link.download = res.headers['content-disposition'] //下载后文件名
        link.download = '订单数据.xls' //下载的文件名
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }
  },

}
</script>
<style lang="less" scoped>
.table-operator{
  margin-bottom: 10px;
}

/deep/ .ant-form-item-label {
  width: 100px !important;
  text-align: left;
}
.ant-btn{
  line-height: 0 !important;
}
/deep/ .ant-form-item-control-wrapper{
  width: 77.8%;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
}
.dislodge-script{
 /deep/ .ant-form-item-label label::after{
    content:'' !important;
  }
}
</style>